import { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import Appliances from "./containers/Appliances/Appliances";
import Login from "./containers/Login/Login";
import SignUp from "./containers/SignUp/SignUp";
import Forgot from "./containers/Forgot/Forgot";
import Reset from "./containers/Reset/Reset";
import NotFound from "./containers/NotFound/NotFound";
import Account from "./containers/Account/Account";
import Locations from "./containers/Locations/Locations";
import ServiceRequestAccept from "./containers/ServiceRequest/Accept";
import ServiceRequestDecline from "./containers/ServiceRequest/Decline";
import ServiceRequestExpired from "./containers/ServiceRequest/Expired";
import ServiceRequestComplete from "./containers/ServiceRequest/Complete";
import ServiceRequestNoRepair from "./containers/ServiceRequest/NoRepair";
import ServiceRequestRetailerAccept from "./containers/ServiceRequest/RetailerAccept";
import ServiceRequestRetailerDecline from "./containers/ServiceRequest/RetailerDecline";
import ProviderRate from "./containers/Provider/Rate";
import ProviderSignUp from "./containers/Provider/SignUp";
import { loadStartup } from "./redux/modules/auth";
import { loadById as loadProviderById } from "./redux/modules/provider";
import detectBrowser from "./utils/detectBrowser";
import { usePageTracking, useQuery } from "./utils/hooks";
import Icon from "./components/Icon/Icon";

const Loading = () => (
  <div className="hero is-fullheight">
    <div className="hero-body is-justify-content-center">
      <div>
        <Icon icon="logo" height="60" width="" />
        <p className="has-text-centered">
          <i className="fa fa-spin fa-spinner" />
        </p>
      </div>
    </div>
  </div>
);

const PrivateRoute = ({ authLoaded, user, children, ...rest }) => (
  <Route
    {...rest}
    render={({ location }) => {
      if (!authLoaded) {
        return <Loading />;
      }

      if (user === null) {
        return <Redirect to={{ pathname: "/", state: { from: location } }} />;
      }

      return children;
    }}
  />
);

const App = ({ authLoaded, loadStartup, loadProviderById, user }) => {
  usePageTracking();
  const query = useQuery();

  useEffect(() => {
    if (typeof query.pid !== "undefined") {
      // eg. 59a63f417cb7842b09150041
      if (/^[0-9a-f]{24}$/.test(query.pid)) {
        loadProviderById(query.pid);
      }
    }
  }, [query.pid, loadProviderById]);

  useEffect(() => {
    const browser = detectBrowser(navigator.userAgent);
    browser.names.forEach((n) => {
      document.body.classList.add(`is-${n}`);
    });
  }, []);

  useEffect(() => {
    if (!authLoaded) {
      loadStartup();
    }
  }, [authLoaded, loadStartup]);

  if (!authLoaded) {
    return <Loading />;
  }

  return (
    <Switch>
      <Route exact path="/" component={Appliances} />

      <Route path="/login" component={Login} />
      <Route
        path="/sign-up"
        component={SignUp}
        /* onEnter={checkLogin} */
      />
      <Route
        path="/forgot"
        component={Forgot}
        /* onEnter={checkLogin} */
      />
      <Route
        path="/reset/:id"
        component={Reset}
        /* onEnter={checkLogin} */
      />

      <PrivateRoute authLoaded={authLoaded} user={user} path="/account">
        <Account />
      </PrivateRoute>
      <PrivateRoute authLoaded={authLoaded} user={user} path="/locations">
        <Locations />
      </PrivateRoute>

      {/* Provider Signup Route */}
      <Route path="/providerSignup/:id" component={ProviderSignUp} />

      {/* ServiceRequest Email Links */}
      <Route path="/accept/:id" component={ServiceRequestAccept} />
      <Route path="/decline/:id" component={ServiceRequestDecline} />
      <Route path="/expired/:id" component={ServiceRequestExpired} />
      <Route path="/complete/:id" component={ServiceRequestComplete} />
      <Route path="/noRepair/:id" component={ServiceRequestNoRepair} />
      <Route
        path="/retailerAccept/:id"
        component={ServiceRequestRetailerAccept}
      />
      <Route
        path="/retailerDecline/:id"
        component={ServiceRequestRetailerDecline}
      />

      <Route path="/rate/:id" component={ProviderRate} />

      <Route path="*" component={NotFound} />
    </Switch>
  );
};

export default connect(
  (state) => ({
    authLoaded: state.auth.loaded,
    user: state.auth.user,
  }),
  {
    loadStartup,
    loadProviderById,
  }
)(App);
